<template>
<!-- 人道救助公示内容 -->
  <div class="personhelp">
      <!-- 人道救助公示内容 -->
      <Newslist :list="persionlist" :nodename="'xxgk_rdjzgs'" @childByValue="childByValue"></Newslist>
  </div>
</template>

<script>
import Newslist from '@/components/newslist.vue'
export default {
  name: 'Personhelp',
  props: {
    persionlist: {
      type: Array,
      default: () =>[]
    }
  },
  components: {
    Newslist
  },
  methods : {
    childByValue (childByValue,nodename) {
      this.$emit('childByValue', childByValue,nodename)
    }
  }
}
</script>

<style lang="less" scoped>
.personhelp {
    width: 908px;
    padding: 39px 40px 41px 40px;
}
</style>
